<template>
	<div class="section-wrapper">
		<cms-text v-if="value.section_title" :value="`${prefix}.section_title`" :props="{variant: 'medium-header', tag: value.header_tag }"/>
		<base-container v-if="isPrivacyPolicyOrTermsAndConditions">
			<div class="content-section privacy-policy" v-html="value.content"></div>
		</base-container>
		<div v-else class="content-section" v-html="value.content"></div>
	</div>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		isPrivacyPolicyOrTermsAndConditions () {
			return this.$app.page.route.meta.alias === 'privacy-policy' || this.$app.page.route.meta.alias === 'terms-and-conditions'
		}
	}
}
</script>

<style scoped lang="scss">
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

.content-section {

	.ql-editor {
		line-height: 24px;
	}

	@include media-breakpoint-up(xl) {
		max-width: 1000px;
		margin: 0 auto;

		.ql-editor {
			line-height: 30px;
		}
	}
}
.content-section.privacy-policy {
	margin: 4rem 0;

	@include media-breakpoint-up(xl) {
		margin: 8rem 0;
	}
}
</style>
