// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!quill/dist/quill.core.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!quill/dist/quill.snow.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "@keyframes in-btn-social-f1f6d65a{0%{left:-110%;top:90%}50%{left:10%;top:-30%}100%{top:-10%;left:-10%}}@keyframes out-btn-social-f1f6d65a{0%{top:-10%;left:-10%}50%{left:10%;top:-30%}100%{left:-110%;top:90%}}.content-section .ql-editor[data-v-f1f6d65a]{line-height:24px}@media(min-width: 1200px){.content-section[data-v-f1f6d65a]{max-width:1000px;margin:0 auto}.content-section .ql-editor[data-v-f1f6d65a]{line-height:30px}}.content-section.privacy-policy[data-v-f1f6d65a]{margin:4rem 0}@media(min-width: 1200px){.content-section.privacy-policy[data-v-f1f6d65a]{margin:8rem 0}}", ""]);
// Exports
module.exports = exports;
